import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Auth } from "aws-amplify";
import { onError } from "../libs/errorLib";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import Button from "react-bootstrap/Button";
import LoaderButton from "../components/LoaderButton";
import "./Confirm.css";

export default function Confirm() {
  const location = useLocation();
  const search = new URLSearchParams(location.search);

  const username = search.get('username') || '';
  const code = search.get('code') || '';
  const clientId = search.get('clientId') || '';
  const email = search.get('email') || '';

  const [isConfirming, setIsConfirming] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const [isResendingEmail, setIsResendingEmail] = useState(false);
  const [isEmailResent, setIsEmailResent] = useState(false);

  async function handleResendEmail(event) {
    event.preventDefault();

    setIsResendingEmail(true);
  
    try {
      await Auth.resendSignUp(email);
      setIsEmailResent(true);
    } catch (e) {
      // console.log(JSON.stringify(e));
      onError(e);
    }

    setIsResendingEmail(false);
  }

  useEffect(() => {
    const confirmUser = async () => {
      // var xhr = new XMLHttpRequest();

      // var url = `https://auth.ais2020.id/confirmUser?client_id=${clientId}&user_name=${username}&confirmation_code=${code}`;

      // console.log(url);

      // xhr.open("GET", url, true);
      // xhr.onload = function (e) {
      //   if (xhr.readyState === 4) {
      //     setIsConfirming(false);
      //     if (xhr.status === 200) {
      //       setIsConfirmed(true);
      //     } else {
      //       console.error(xhr.statusText);
      //       setIsConfirmed(false);
      //     }
      //   }
      // }.bind(this);
      // xhr.onerror = function (e) {
      //   console.error(xhr.statusText);
      //   setIsConfirming(false);
      //   setIsConfirmed(false);
      // };
      // xhr.send(null);

      try {
        await Auth.confirmSignUp(username, code);
        setIsConfirming(false);
        setIsConfirmed(true);
      } catch (err) {
        setIsConfirming(false);
        setIsConfirmed(false);
      }
    };
    confirmUser();
  }, [username, code, clientId]);

  return (
    <div className="Confirm">
      {isConfirming
        ? <div>
            <FontAwesomeIcon icon={faSpinner} spin size="3x" /><br /> Validating registration...
          </div>
        : <div>
            {isConfirmed
              ? <div>
                  <FontAwesomeIcon icon={faCheck} size="3x" /
                  ><br />
                  Registration confirmed!
                  <br />
                  <br />
                  <Button
                    type="submit"
                    bssize="large"
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href='/login';
                      }}
                  >
                    Go to Login Page
                  </Button>
                </div>
              : <div>
                  <FontAwesomeIcon icon={faTimes} size="3x" />
                  <br />
                  Invalid link provided, please request a link again.
                  <br />
                  <br />
                  <LoaderButton
                    variant={isEmailResent ? "primary" : "primary"}
                    onClick={handleResendEmail}
                    isLoading={isResendingEmail}
                    disabled={isEmailResent}
                    >
                    {isEmailResent ? 'Resent! Please check your inbox' : 'Resend Confirmation Email'}
                  </LoaderButton>
                </div>
            }
          </div>
      }
    </div>
  );
}