import React, { useState, useEffect } from "react";
import "./Card.css";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhone, faEnvelope, faDoorOpen } from '@fortawesome/free-solid-svg-icons'
import { useAppContext } from "../libs/contextLib";
import * as CONSTANTS from "../constants";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const Card = (props) => {
  const history = useHistory();
  // const { userProfile } = useAppContext();
  const { handleLogout } = props;

  const [virtualExpoWindow, setVirtualExpoWindow] = useState(null);
  const [isGuidanceModalShowing, setIsGuidanceModalShowing] = useState(false);

  const calculateTimeLeft = (difference) => {
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        ts: difference,
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  }

  const initCountDownDate = new Date(Date.UTC(CONSTANTS.COUNTDOWN_TIMER_YEAR, 
    CONSTANTS.COUNTDOWN_TIMER_MONTH,
    CONSTANTS.COUNTDOWN_TIMER_DAY,
    CONSTANTS.COUNTDOWN_TIMER_HOUR,
    CONSTANTS.COUNTDOWN_TIMER_MINUTE,
    CONSTANTS.COUNTDOWN_TIMER_SECOND)).getTime();
  let initNow = new Date().getTime();
  let initDifference = initCountDownDate - initNow;
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(initDifference));

  function showVisitorGuidance() {
    history.push("/visitor-guide");
    // setIsGuidanceModalShowing(true);
  }

  function handleGuidanceModalClose() {
    setIsGuidanceModalShowing(false);
  }

  function openVirtualExpoTab(event) {
    if (virtualExpoWindow == null || virtualExpoWindow.closed)
      setVirtualExpoWindow(window.open('/exhibition', 'uss2020VirtualBazaarWindow'));
    else
      virtualExpoWindow.focus();
  }

  useEffect(() => {
    const countDownDate = new Date(Date.UTC(CONSTANTS.COUNTDOWN_TIMER_YEAR, 
      CONSTANTS.COUNTDOWN_TIMER_MONTH,
      CONSTANTS.COUNTDOWN_TIMER_DAY,
      CONSTANTS.COUNTDOWN_TIMER_HOUR,
      CONSTANTS.COUNTDOWN_TIMER_MINUTE,
      CONSTANTS.COUNTDOWN_TIMER_SECOND)).getTime();
    
    const timer = setInterval(() => {
      // Get today's date and time
      let now = new Date().getTime();
      let difference = countDownDate - now;

      if (difference > 0) {
        setTimeLeft(calculateTimeLeft(difference));
      } else {
        setTimeLeft({
          ts: 0,
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        });
        clearInterval(timer);
      }
    }, 1000);
    // Clear interval if the component is unmounted
    return () => clearInterval(timer);
  }, []);

  const timerComponents = [];

  if (timeLeft['ts'] > 0) {
    timerComponents.push(
      <div className="Congrats" key="congrats">
        <h2>Hang tight!</h2>
        <br />
        <p>Congratulations in joining Indonesia's first and biggest Artificial Intelligence Virtual Exhibition.</p>
        <p>AI Summit 2020 will open in...</p>
        <div className="countdown d-flex justify-content-center">
          <div>
            <h3 id="day">{timeLeft['days']}</h3>
            <h4>Days</h4>
          </div>
          <div>
            <h3 id="hour">{timeLeft['hours']}</h3>
            <h4>Hours</h4>
          </div>
          <div>
            <h3 id="minute">{timeLeft['minutes']}</h3>
            <h4>Minutes</h4>
          </div>
          <div>
            <h3 id="second">{timeLeft['seconds']}</h3>
            <h4>Seconds</h4>
          </div>
        </div>
      </div>
    );
  }

  const timesUp = (
    <div className="Congrats">
      <h2>It's Open!</h2>
      <br />
      <p>Artifical Intelligence Summit 2020 is here.</p>
      <br />
      <Button size="lg" onClick={openVirtualExpoTab}>
        <FontAwesomeIcon icon={faDoorOpen} size="3x" /><br />
        Enter Virtual Exhibition
      </Button>
    </div>
  )

  return (
    <div className="Card container">

      <div className="row d-flex justify-content-between">
        <div className="col-lg-5 col-md-12 col-sm-12">
          <img className="formLogo" src="./img/logo.png" srcSet="./img/logo@2x.png" alt="USS 2020" />
        </div>

        <div className="col-lg-5 col-md-12 col-sm-12">
        <h2>It's Open!</h2>
        <br />
        <p>USS Virtual Bazaar 2020 is here.</p>
        <br />
        <Button size="lg" onClick={openVirtualExpoTab}>
          <FontAwesomeIcon icon={faDoorOpen} size="3x" /><br />
          Enter Virtual Bazaar
        </Button>
        <br />
        <br />
        <br />
        <br />
        <Button className="logoutButton" variant="danger" onClick={() => handleLogout()}>
          Logout
        </Button>
        </div>
      </div>

      <Modal
        show={isGuidanceModalShowing}
        onHide={handleGuidanceModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="visitorGuide"
        className="visitorGuideContainer"
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Visitor Guidance</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <img src="./img/visitor_guidance.png" alt="Visitor guidance" />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={openVirtualExpoTab}>Got it!</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Card;