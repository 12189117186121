import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import LoaderButton from "../components/LoaderButton";
import { Auth } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";

import "./Login.css";

export default function Login() {
  const { userHasAuthenticated, userHasProfile } = useAppContext();

  const history = useHistory();
  
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  });
  // const [fields, handleFieldChange] = useFormFields({
  //   email: "reyn@virtu.co.id",
  //   password: "Password",
  // });

  const [isResendingEmail, setIsResendingEmail] = useState(false);
  const [isEmailResent, setIsEmailResent] = useState(false);
  
  const [isModalShowing, setModalShowing] = useState(false);
  const [noticeModalTitle, setNoticeModalTitle] = useState('');
  const [noticeModalMessage, setNoticeModalMessage] = useState('');
  const handleNoticeModalClose = () => setModalShowing(false);
  const handleNoticeModalShow = () => setModalShowing(true);

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
  
    try {
      await Auth.signIn(fields.email, fields.password);

      let currentUserInfo = await Auth.currentUserInfo()
      userHasProfile(currentUserInfo.attributes);
      userHasAuthenticated(true);

      history.push("/card");
    } catch (e) {
      // console.log(JSON.stringify(e));
      if (e.code === 'UserNotConfirmedException') {
        let msg = `You should have received a verification email in your inbox (${fields.email}). If you have not received it, you may request to resend the verification email.`;

        setIsEmailResent(false);
        setNoticeModalTitle('Please confirm your email');
        setNoticeModalMessage(msg);
        handleNoticeModalShow();
      } else {
        onError(e);
      }
      setIsLoading(false);
    }


  }

  async function handleResendEmail(event) {
    event.preventDefault();

    setIsResendingEmail(true);
  
    try {
      await Auth.resendSignUp(fields.email);
      setIsEmailResent(true);
    } catch (e) {
      // console.log(JSON.stringify(e));
      onError(e);
    }

    setIsResendingEmail(false);
  }

  return (
    <div className="">
      <div className="Login container">
        <div className="row d-flex justify-content-between">
          <div className="col-lg-5 col-md-12 col-sm-12">
            <img className="formLogo" src="./img/logo.png" srcSet="./img/logo@2x.png" alt="USS 2020" />
          </div>

          <div className="col-lg-5 col-md-12 col-sm-12">
            <h1>Login</h1>
            <form onSubmit={handleSubmit}>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="email@domain.com"
                  value={fields.email}
                  onChange={handleFieldChange} />
                {/* <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text> */}
              </Form.Group>

              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="********"
                  value={fields.password}
                  onChange={handleFieldChange} />
              </Form.Group>

              <Link to="/reset-password">Forgot password?</Link>

              <LoaderButton
                block
                type="submit"
                bssize="large"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Login
              </LoaderButton>
            </form>
            <br />
            <p className="text-center">
              Don't have an account yet? <a href="/register">Register Now</a>
            </p>
          </div>
        </div>
      </div>

      <Modal
        show={isModalShowing}
        onHide={handleNoticeModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>{noticeModalTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{noticeModalMessage}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleNoticeModalClose}>Dismiss</Button>
          <LoaderButton
            variant={isEmailResent ? "primary" : "primary"}
            onClick={handleResendEmail}
            isLoading={isResendingEmail}
            disabled={isEmailResent}
            >
            {isEmailResent ? 'Resent! Please check your inbox' : 'Resend Email'}
          </LoaderButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
}