import React from "react";
import { Route, Switch } from "react-router-dom";
import UnauthenticatedRoute from "./UnauthenticatedRoute";
import AuthenticatedRoute from "./AuthenticatedRoute";
import Confirm from "./containers/Confirm";
import Terms from "./containers/Terms";
import Privacy from "./containers/Privacy";
import Login from "./containers/Login";
import Register from "./containers/Register";
import ResetPassword from "./containers/ResetPassword";
import Card from "./containers/Card";
import Exhibition from "./containers/Exhibition";
import NotFound from "./containers/NotFound";

export default function Routes({ isAuthenticated, handleLogout, handleUserDidUpdateProfile, ...props }) {
  return (
    <Switch>
      <Route exact path="/confirm">
        <Confirm />
      </Route>
      <Route exact path="/terms">
        <Terms />
      </Route>
      <Route exact path="/privacy">
        <Privacy />
      </Route>

      <UnauthenticatedRoute exact path="/" appProps={{ isAuthenticated }}>
        <Login />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/register" appProps={{ isAuthenticated }}>
        <Register />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/reset-password">
        <ResetPassword />
      </UnauthenticatedRoute>

      <AuthenticatedRoute exact path="/card">
        <Card handleLogout={handleLogout} />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/exhibition">
        <Exhibition />
      </AuthenticatedRoute>
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}