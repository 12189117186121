import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import "./ResetPassword.css";

export default function ResetPassword() {
  const [fields, handleFieldChange] = useFormFields({
    code: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [codeSent, setCodeSent] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  
  const [isModalShowing, setModalShowing] = useState(false);
  const [noticeModalTitle, setNoticeModalTitle] = useState('');
  const [noticeModalMessage, setNoticeModalMessage] = useState('');
  const handleNoticeModalClose = () => setModalShowing(false);
  const handleNoticeModalShow = () => setModalShowing(true);

  function validateCodeForm() {
    return fields.email.length > 0;
  }

  function validateResetForm() {
    return (
      fields.code.length > 0 &&
      fields.password.length > 7 &&
      fields.password === fields.confirmPassword
    );
  }

  async function handleSendCodeClick(event) {
    event.preventDefault();

    setIsSendingCode(true);

    try {
      await Auth.forgotPassword(fields.email);
      setCodeSent(true);
    } catch (error) {
      setNoticeModalTitle('Oops!');
      setNoticeModalMessage(error.message);
      handleNoticeModalShow();
      setIsSendingCode(false);
    }
  }

  async function handleConfirmClick(event) {
    event.preventDefault();

    setIsConfirming(true);

    try {
      await Auth.forgotPasswordSubmit(
        fields.email,
        fields.code,
        fields.password
      );
      setConfirmed(true);
    } catch (error) {
      setNoticeModalTitle('Oops!');
      setNoticeModalMessage(error.message);
      handleNoticeModalShow();
      setIsConfirming(false);
    }
  }

  function renderRequestCodeForm() {
    return (
      <form onSubmit={handleSendCodeClick}>
        <Form.Group bssize="large" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <LoaderButton
          block
          type="submit"
          bssize="large"
          isLoading={isSendingCode}
          disabled={!validateCodeForm()}
        >
          Send Confirmation
        </LoaderButton>
      </form>
    );
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirmClick}>
        <Form.Group bsSize="large" controlId="code">
          <Form.Label>Confirmation Code</Form.Label>
          <Form.Control
            autoFocus
            type="tel"
            value={fields.code}
            onChange={handleFieldChange}
          />
          <Form.Text>
            Please check your email ({fields.email}) for the confirmation code.
          </Form.Text>
        </Form.Group>
        <hr />
        <Form.Group bsSize="large" controlId="password">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group bsSize="large" controlId="confirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.confirmPassword}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isConfirming}
          disabled={!validateResetForm()}
        >
          Confirm
        </LoaderButton>
      </form>
    );
  }

  function renderSuccessMessage() {
    return (
      <div className="success">
        <FontAwesomeIcon icon={faCheck} />
        <p>Your password has been reset.</p>
        <p>
          <Link to="/login">
            Click here to login with your new credentials.
          </Link>
        </p>
      </div>
    );
  }

  return (
    <div className="">
      <div className="ResetPassword container">
        <div className="row d-flex justify-content-between">
          <div className="col-lg-5 col-md-12 col-sm-12">
            <img className="formLogo" src="./img/logo.png" srcSet="./img/logo@2x.png" alt="USS 2020" />
          </div>

          <div className="col-lg-5 col-md-12 col-sm-12">
            <h1>Reset Password</h1>
            {!codeSent
              ? renderRequestCodeForm()
              : !confirmed
              ? renderConfirmationForm()
              : renderSuccessMessage()}
          </div>
        </div>
      </div>

      <Modal
        show={isModalShowing}
        onHide={handleNoticeModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>{noticeModalTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{noticeModalMessage}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleNoticeModalClose}>Dismiss</Button>
          <Button variant="primary"
            onClick={(e) => {
              e.preventDefault();
              window.location.href='/login';
            }}>
            Go to Login Page
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}