import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// import Image from "react-bootstrap/Image";
import { Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import "./Register.css";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

export default function Register() {
  const [fields, handleFieldChange] = useFormFields({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    name: "",
    gender: "",
    dobDay: "",
    dobMonth: "",
    dobYear: "",
    country: "",
    region: "",
    agreeTnC: false
  });
  // const [fields, handleFieldChange] = useFormFields({
  //   username: "reyn",
  //   email: "reyn@virtu.co.id",
  //   password: "Password",
  //   confirmPassword: "Password",
  //   phoneNumber: "+6281211991818",
  //   name: "Reyn Aria",
  //   gender: "male",
  //   dobDay: "27",
  //   dobMonth: "08",
  //   dobYear: "1986",
  //   country: "Indonesia",
  //   region: "Jakarta Raya",
  //   agreeTnC: true
  // });
  const history = useHistory();
  const [newUser, setNewUser] = useState(null);
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [registrationPage, setRegistrationPage] = useState(1);
  const [country, setCountry] = useState('Indonesia');
  const [region, setRegion] = useState('Jakarta Raya');

  const [isModalShowing, setModalShowing] = useState(false);
  const [noticeModalTitle, setNoticeModalTitle] = useState('');
  const [noticeModalMessage, setNoticeModalMessage] = useState('');
  const handleNoticeModalClose = () => setModalShowing(false);
  const handleNoticeModalShow = () => setModalShowing(true);

  const label = ( <div> <span>I accept the </span> <Link to={'/terms'} target="_blank">terms of use</Link> <span> and </span> <Link to={'/privacy'} target="_blank">privacy policy</Link> </div> );

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 7 &&
      fields.password === fields.confirmPassword &&
      fields.phoneNumber.length > 0 &&
      fields.name.length > 3 &&
      fields.gender.length > 0 &&
      fields.dobDay.length > 0 &&
      fields.dobMonth.length > 0 &&
      fields.dobYear.length > 0
    );
  }

  function validateEmailPasswordForm() {
    return (
      fields.username.length >= 3 &&
      fields.email.length > 0 &&
      fields.password.length > 7 &&
      fields.password === fields.confirmPassword &&
      fields.agreeTnC
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    if (registrationPage === 1) {
      // setRegistrationPage(2);

      try {
        // This will always throw exception,
        // but we only want to check if username exists
        await Auth.signUp({
          username: fields.username,
          email: fields.email,
          password: fields.password
        });
      } catch (e) {
        if (e.code === 'UsernameExistsException')
        {
          setNoticeModalTitle('Oops!');
          setNoticeModalMessage('This email has already been registered. Please login instead.');
          handleNoticeModalShow();
        }
        else 
        {
          setRegistrationPage(2);
        }
      }
    } else {
      // history.push("/card");

      // console.log(fields);

      try {
        const newUser = await Auth.signUp({
          username: fields.username,
          password: fields.password,
          attributes: {
            phone_number: fields.phoneNumber,
            email: fields.email,
            name: fields.name,
            birthdate: fields.dobYear + "-" + fields.dobMonth + "-" + fields.dobDay,
            gender: fields.gender,
            // 'custom:institution': fields.institution,
            // 'custom:job_title': fields.jobTitle,
            // 'custom:country': fields.country,
            // 'custom:region': fields.region
          }
        });
        setNewUser(newUser);
      } catch (e) {
        setNoticeModalTitle('Oops!');
        setNoticeModalMessage(e.message);
        handleNoticeModalShow();
      }
    }
    setIsLoading(false);
  }

  // async function handleFirstPageSubmit(event) {
  //   event.preventDefault();

  //   setIsLoading(true);

  //   try {
  //     // This will always throw exception,
  //     // but we only want to check if username exists
  //     await Auth.signUp({
  //       username: fields.email,
  //       password: fields.password
  //     });
  //   } catch (e) {
  //     if (e.code === 'UsernameExistsException')
  //     {
  //       setNoticeModalTitle('Oops!');
  //       setNoticeModalMessage('This email has already been registered. Please login instead.');
  //       handleNoticeModalShow();
  //     }
  //     else 
  //     {
  //       setRegistrationPage(2);
  //     }
  //   }

  //   setIsLoading(false);
  // }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      await Auth.signIn(fields.email, fields.password);
  
      userHasAuthenticated(true);
      history.push("/");
    } catch (e) {
      setNoticeModalTitle('Oops!');
      setNoticeModalMessage(e.message);
      handleNoticeModalShow();
      setIsLoading(false);
    }
  }

  function handleCheckboxAgreeTnC(event) {
    fields.agreeTnC = event.target.checked;
    handleFieldChange(event);
  }

  function handleGenderRadioChangeMale(event) {
    fields.gender = 'male';
    handleFieldChange(event);
  }

  function handleGenderRadioChangeFemale(event) {
    fields.gender = 'female';
    handleFieldChange(event);
  }
 
  function selectCountry (val) {
    fields.country = val;
    setCountry(val);
  }
 
  function selectRegion (val) {
    fields.region = val;
    setRegion(val);
  }

  function renderConfirmationPage() {
    return (
      <form onSubmit={handleConfirmationSubmit} className="col-sm-12 my-auto">
        <br />
        <Form.Group controlId="confirmationCode" bssize="large">
          <Form.Label>Confirm Your Email</Form.Label>
          <p>Thank you for registering to AI Summit 2020. In order to confirm your registration, please check your inbox and click on the verification link.</p>
        </Form.Group>
        <Button
          block
          type="submit"
          bssize="large"
          onClick={(e) => {
            e.preventDefault();
            window.location.href='/';
            }}
        >
          Go to Login Page
        </Button>
      </form>
    );
  }

  function dobDayDidChange(event) {
    fields.dobDay = event.target.value;
  }

  function dobMonthDidChange(event) {
    fields.dobMonth = event.target.value;
  }

  function dobYearDidChange(event) {
    fields.dobYear = event.target.value;
  }
  function renderForm() {
    var dayOptions = [];
    dayOptions.push(<option key="" value="">Day</option>);
    for (var i=1; i<=31; i++) {
      dayOptions.push(<option key={i<10 ? "0" + i : i} value={i<10 ? "0" + i : i}>{i}</option>);
    }

    var yearOptions = [];
    yearOptions.push(<option key="" value="">Year</option>);
    for (i=2020; i>1920; i--) {
      yearOptions.push(<option key={i} value={i}>{i}</option>);
    }

    return (
      <form onSubmit={handleSubmit}>
        <div style={{ display: (registrationPage===1 ? 'block' : 'none') }}>
        <Form.Group controlId="username" bssize="large">
          <Form.Label>Username*</Form.Label>
          <Form.Control
            type="text"
            placeholder="e.g. SneakerHead99"
            value={fields.username}
            onChange={handleFieldChange}
          />
          <Form.Text className="text-muted">
            This will be your avatar name. Can only contain letters (A-Z, a-z) and numbers (0-9). Minimum 3 characters.
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="email" bssize="large">
          <Form.Label>Email*</Form.Label>
          <Form.Control
            type="email"
            placeholder="e.g. email@domain.com"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="password" bssize="large">
          <Form.Label>Password*</Form.Label>
          <Form.Control
            type="password"
            placeholder="********"
            value={fields.password}
            onChange={handleFieldChange}
          />
          <Form.Text className="text-muted">
            Should have at least 1 uppercase letter and 1 lowercase letter. Minimum 8 characters.
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="confirmPassword" bssize="large">
          <Form.Label>Confirm Password*</Form.Label>
          <Form.Control
            type="password"
            placeholder="********"
            onChange={handleFieldChange}
            value={fields.confirmPassword}
          />
        </Form.Group>
        <Form.Group>
          <Form.Check
            type="checkbox"
            label={label}
            checked={fields.agreeTnC}
            onChange={handleCheckboxAgreeTnC} />
        </Form.Group>
        <LoaderButton
          block
          // onClick={handleFirstPageSubmit}
          type="submit"
          bssize="large"
          isLoading={isLoading}
          disabled={!validateEmailPasswordForm()}
        >
          Next
        </LoaderButton>
        </div>

        <div style={{ display: (registrationPage===2 ? 'block' : 'none') }}>
        <Form.Group controlId="name" bssize="large">
          <Form.Label>Full Name*</Form.Label>
          <Form.Control
            type="name"
            placeholder="e.g. John Doe"
            value={fields.name}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="phoneNumber" bssize="large">
          <Form.Label>Phone Number*</Form.Label>
          <Form.Control
            type="phone"
            placeholder="e.g. +6281812345678"
            value={fields.phoneNumber}
            onChange={handleFieldChange}
          />
          <Form.Text className="text-muted">
              Should starts with country code e.g. +62.
            </Form.Text>
        </Form.Group>
            <Form.Label>Date of Birth*</Form.Label>
        <Form.Row>
          &nbsp;
          <Form.Group className="mr-sm-4" controlId="dobDay">
            <Form.Control as="select" onChange={dobDayDidChange} defaultValue={fields.dobDay}>
              { dayOptions }
            </Form.Control>
          </Form.Group>
          <Form.Group className="mr-sm-4" controlId="dobMonth">
            <Form.Control as="select" onChange={dobMonthDidChange} defaultValue={fields.dobMonth}>
              <option key="00" value="">Month</option>
              <option key="01" value="01">Jan</option>
              <option key="02" value="02">Feb</option>
              <option key="03" value="03">Mar</option>
              <option key="04" value="04">Apr</option>
              <option key="05" value="05">May</option>
              <option key="06" value="06">Jun</option>
              <option key="07" value="07">Jul</option>
              <option key="08" value="08">Aug</option>
              <option key="09" value="09">Sep</option>
              <option key="10" value="10">Oct</option>
              <option key="11" value="11">Nov</option>
              <option key="12" value="12">Dec</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="mr-sm-4" controlId="dobYear">
            <Form.Control as="select" onChange={dobYearDidChange} defaultValue={fields.dobYear}>
              { yearOptions }
            </Form.Control>
          </Form.Group>
        </Form.Row>
        <fieldset>
          <Form.Group>
            <Form.Label>Gender*</Form.Label>
            <div>
              <Form.Check
                inline
                type="radio"
                label="Male"
                name="gender"
                value="male"
                id="genderMale"
                checked={fields.gender==="male"}
                onClick={handleGenderRadioChangeMale}
                onChange={ () => {} }
              />
              <Form.Check
                inline
                type="radio"
                label="Female"
                name="gender"
                value="female"
                id="genderFemale"
                checked={fields.gender==="female"}
                onClick={handleGenderRadioChangeFemale}
                onChange={ () => {} }
              />
            </div>
          </Form.Group>
        </fieldset>
        {/* <Form.Group controlId="institution" bssize="large">
          <Form.Label>Institution*</Form.Label>
          <Form.Control
            type="name"
            placeholder="e.g. PT Indonesia Raya"
            value={fields.institution}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="jobTitle" bssize="large">
          <Form.Label>Job Title*</Form.Label>
          <Form.Control
            type="name"
            placeholder="e.g. IT Manager"
            value={fields.jobTitle}
            onChange={handleFieldChange}
          />
        </Form.Group> */}
        {/* <Form.Group>
          <Form.Label>Country</Form.Label>
          <CountryDropdown
            className="form-control"
            defaultOptionLabel="-"
            value={country}
            onChange={selectCountry} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Region</Form.Label>
          <RegionDropdown
            className="form-control"
            blankOptionLabel="-"
            defaultOptionLabel="-"
            country={country}
            value={region}
            onChange={selectRegion} />
        </Form.Group> */}
        <br/>
        <LoaderButton
          block
          type="submit"
          bssize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Register
        </LoaderButton>
        </div>

        <Modal
          show={isModalShowing}
          onHide={handleNoticeModalClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          >
          <Modal.Header closeButton>
            <Modal.Title>{noticeModalTitle}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>{noticeModalMessage}</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleNoticeModalClose}>Dismiss</Button>
            <Button variant="primary"
              onClick={(e) => {
                e.preventDefault();
                window.location.href='/';
              }}>
              Go to Login Page
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    );
  }

  return (
    <div className="h-100">
      <div className="Signup container">
        <div className="row d-flex justify-content-between">
          <div className="col-lg-5 col-md-12 col-sm-12">
            <img className="formLogo" src="./img/logo.png" srcSet="./img/logo@2x.png" alt="USS 2020" />
          </div>

          <div className="col-lg-5 col-md-12 col-sm-12">
            <h1>Registration</h1>
            {newUser === null ? renderForm() : renderConfirmationPage()}
          </div>
        </div>
      </div>
    </div>
  );
}