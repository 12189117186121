import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Routes from "./Routes";
// import TransSolidScrollNavBar from "./components/TransSolidScrollNavBar";
// import Nav from "react-bootstrap/Nav";
import "./App.css";
import { Amplify, Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import { AppContext } from "./libs/contextLib";
import { onError } from "./libs/errorLib";

Amplify.configure(awsconfig);

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [userProfile, userHasProfile] = useState(null);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    async function onLoad() {
      try {
        await Auth.currentSession();
        userHasAuthenticated(true);
  
        let user = await Auth.currentUserInfo();
        userHasProfile(user.attributes);
      }
      catch(e) {
        if (e !== 'No current user') {
          onError(e);
        }
      }
    
      setIsAuthenticating(false);
    }
    onLoad();
  }, []);

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);
    userHasProfile(null);

    history.push("/");
  }

  function handleUserDidUpdateProfile(newUserProfile) {
    userHasProfile(newUserProfile.attributes);
  }

  return (
    !isAuthenticating &&
    <div className="h-100">
      {/* { location.pathname !== '/exhibition' &&
      <TransSolidScrollNavBar>
        {isAuthenticated
          ? <Nav>
              <Nav.Link href="/sponsorship" className={location.pathname === "/sponsorship" ? "active" : ""}>Sponsorship</Nav.Link>
              <Nav.Link href="/programme" className={location.pathname === "/programme" ? "active" : ""}>Webinar Program</Nav.Link>
              <Nav.Link href="/card" className="registerBtn">Your Ticket</Nav.Link>
            </Nav>
          : <Nav>
              <Nav.Link href="/sponsorship" className={location.pathname === "/sponsorship" ? "active" : ""}>Sponsorship</Nav.Link>
              <Nav.Link href="/programme" className={location.pathname === "/programme" ? "active" : ""}>Webinar Program</Nav.Link>
              <Nav.Link href="/login" className={location.pathname === "/login" ? "active" : ""}>Visitor Login</Nav.Link>
              <Nav.Link href="/register" className="registerBtn">Visitor Registration</Nav.Link>
          </Nav>
        }
      </TransSolidScrollNavBar>
      } */}

      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, userProfile, userHasProfile }}>
        <div id={ location.pathname !== '/exhibition' ? "routeContainer" : "" }>
          <Routes isAuthenticated={isAuthenticated} handleLogout={handleLogout} handleUserDidUpdateProfile={handleUserDidUpdateProfile} />
        </div>

        { location.pathname !== '/exhibition' &&
        <div className="container footer">
          <div className="social-links text-center">
            <p>
              <a href="https://twitter.com/urbansneakersoc" className="twitter" target="_blank" rel="noopener noreferrer"><i className="icofont-twitter"></i></a>
              <a href="https://www.facebook.com/urbansneakersociety/" className="facebook" target="_blank" rel="noopener noreferrer"><i className="icofont-facebook"></i></a>
              <a href="https://www.instagram.com/urbansneakersociety" className="instagram" target="_blank" rel="noopener noreferrer"><i className="icofont-instagram"></i></a>
            </p>
          </div>
          <p>
            Copyright © 2020 Urban Sneaker Society
            <br />All Rights Reserved
            <br />
            <a href="/privacy">Privacy Policy</a>
            &nbsp;•&nbsp;
            <a href="/terms">Terms of Use</a>
          </p>
        </div>
        }
      </AppContext.Provider>
    </div>
  );
}

export default App;
