import React, { useEffect, useState } from "react";
import Unity, { UnityContent } from "react-unity-webgl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagramSquare } from "@fortawesome/free-brands-svg-icons"
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import "./Exhibition.css";

const unityContent = new UnityContent(
  "https://s3-ap-southeast-1.amazonaws.com/virtu.co.id/static/space.virtu.co.id/multiplayer-isometric-2d/Build/multiplayer2dSocketIO.json",
  "https://s3-ap-southeast-1.amazonaws.com/virtu.co.id/static/space.virtu.co.id/multiplayer-isometric-2d/Build/UnityLoader.js"
);

let Exhibition = () => {
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const resizeWindow = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  // const delay = ms => new Promise(res => setTimeout(res, ms));

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  unityContent.on("progress", progression => {
    setLoadingProgress(progression);
  });


  let containerStyle = {
    width: windowWidth + "px",
    height: windowHeight + "px",
  };

  return (
    <div style={containerStyle} id="Exhibition">
      <div style={containerStyle} >
        <Unity unityContent={unityContent}/>

      </div>

      { loadingProgress != 1 &&
        <div id="virtuLoadingPanel" className="overlay">
          <div className="overlayColor"></div>
          <div id="loadingLogo"></div>
          <div id="loadingProgressBar">
            <div id="loadingProgressEmpty" style={{width: `${(1 - loadingProgress) * 100}%`}}></div>
            <div id="loadingProgressFull" style={{width: `${loadingProgress * 100}%`}}></div>
          </div>
          <div id="loadingProgressText">
            { loadingProgress < 1 ?
              <span>constructing virtual world: {parseInt(loadingProgress * 100)}%</span>
            :
              <span>commencing teleportation <FontAwesomeIcon icon={faSpinner} spin /></span>
            }
          </div>
          <div id="loadingCopyright">
            Virtual Exhibition Platform by
            <div id="loadingCopyrightLogo">
              <a target="_blank" href="https://www.virtu.co.id" rel="noopener noreferrer">
                <img width="90" className="shadowed" src="/img/virtu_logo_web_blue.png" srcSet="/img/virtu_logo_web_blue@2x.png 2x" alt="Virtu" />
              </a>
              <span>virtu.co.id</span>
            </div>
          </div>
        </div>
      }

    </div>
  );
}

export default Exhibition;