import React from "react";
import "./NotFound.css";

export default function NotFound() {
  return (
    <div className="h-100">
      <div className="NotFound">
        <img className="formLogo" src="./img/logo.png" srcSet="./img/logo@2x.png" alt="AI Summit 2020" />
        <br />
        <br />
        <h1>404</h1>
        <h3>Sorry, page not found!</h3>
      </div>
    </div>
  );
}